import React, { useState, useEffect } from 'react'
import NumberFormat from 'react-number-format';
import '../assets/styles/Form.css'
import "../assets/styles/StepCounter.css";

import iconCheckBlue from '../assets/images/iconCheckBlue.png';
import iconArrowBack from '../assets/images/iconArrowBack.png';
import iconSadFace from '../assets/images/iconSadFace.png';
import iconAdmiration from '../assets/images/iconAdmiration.png';

import Notification from './Notification';
import SpinnerPopUp from './SpinnerPopUp';
import NotificationPinnacleOff from './NotificationPinnacleOff'

import useIsSafari from '../hooks/useIsSafari'
import moment from 'moment/moment.js'
require('dotenv').config();

const PinnacleForm = ({dealerUUID}) => {

    //States
    const isSafari = useIsSafari()

    const [isLoading, setIsLoading] = useState(false);

    const [step, setStep] = useState(1);

    //States for creating a loan application
    const [applicant, setApplicant] = useState({HasCoApplicant:"default"});
    const [coApplicant, setCoApplicant] = useState({});
    const [loan, setLoan] = useState({});
    const [hasCoApplicant, setHasCoapplicant] = useState(null);
    const [hasFreeAndClearHomeOwnership, setHasFreeAndClearHomeOwnership] = useState(false);
    const [hasCoApplicantValue, setHasCoapplicantValue] = useState({HasCoApplicant:"default"});
    const [showNotificationPinnacleParametersNotSeat, setShowNotificationPinnacleParametersNotSeat] = useState(false);
    const [errorResponse, setErrorResponse] = useState({})

    //handler includetax
    const [includeTax, setIncludeTax] = useState(false);

    //handler creditType
    const [changeInputTax, setChangeInputTax] = useState(false);

    const [validateRecaptcha, setValidateRecaptcha] = useState(false);
    const [errors, setErrors] = useState({});
    const [errorsCoApplicant, setErrorsCoApplicant] = useState({});
    const [errorsLoan, setErrorsLoan] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(0);
    const [isSubmittingCoApplicant, setIsSubmittingCoApplicant] = useState(0);
    const [isSubmittingTerms, setIsSubmittingTerms] = useState(0);
    const [isSubmittingLoan, setIsSubmittingLoan] = useState(0);
    const [termsCheckBorderRed, setTermsCheckBorderRed] = useState(false)
    const [termsCheck, setTermsCheck] = useState(false)

    const [showNotificationOk, SetShowNotificationOk] = useState(false);
    const [showNotificationError, SetShowNotificationError] = useState(false)
    const [showNotificationErrorTimeout, SetShowNotificationErrorTimeout] = useState(false)    

    const [FIApplicationId, setFIApplicationId] = useState('')

    //POST APPLICATION
    const handleSubmitAllData = async (event) => {

        setIsLoading(true);

        //Armar el objeto que se envia
        const formData = {};

        formData.Applicant = applicant;
        formData.LoanParameters = loan;

        if (hasCoApplicant === true) {
            formData.CoApplicant = coApplicant;
        }

        //Adding HomePhone
        //formData.Applicant.HomePhone = applicant.CellPhone;

        //-----------------------------------------------------------------------------------------      

        //Adding GrossMonthlyIncome
        if (!applicant.GrossMonthlyIncome) {
            formData.Applicant.GrossMonthlyIncome = 0
        }
        if (hasCoApplicant === true) {
            if (!coApplicant.GrossMonthlyIncome) {
                formData.CoApplicant.GrossMonthlyIncome = 0
            }
        }

        //CONVERT FLOAT LOANPARAMETERS
        formData.LoanParameters.LoanAmount = parseFloat(formData.LoanParameters.LoanAmount);
        formData.LoanParameters.DownPayment = parseFloat(formData.LoanParameters.DownPayment);
        formData.Applicant.GrossMonthlyIncome = parseFloat(formData.Applicant.GrossMonthlyIncome);
        if(!formData.Applicant.MortgagePayment) formData.Applicant.MortgagePayment = parseFloat(formData.Applicant.MortgagePayment);
        if(!formData.Applicant.MortgageBalance) formData.Applicant.MortgageBalance = parseFloat(formData.Applicant.MortgageBalance);
        if (hasCoApplicant === true) {
            formData.CoApplicant.GrossMonthlyIncome = parseFloat(formData.CoApplicant.GrossMonthlyIncome);
        }

        formData.DealerUUID = dealerUUID

        console.log(formData)
        
        const dateMovedIntoHome_m =  moment(formData.Applicant.DateMovedIntoHome, 'YYYY-MM-DD');
        const diffMovedIntoDuration = moment.duration(moment().diff(dateMovedIntoHome_m));
        formData.Applicant.MovedIntoYears = diffMovedIntoDuration.years()
        formData.Applicant.MovedIntoMonths = diffMovedIntoDuration.months()

        const hiredDate_m =  moment(formData.Applicant.EmploymentStartDate, 'YYYY-MM-DD');
        const diffHiredDateDuration = moment.duration(moment().diff(hiredDate_m));
        formData.Applicant.HiredYears = diffHiredDateDuration.years()
        formData.Applicant.HiredMonths = diffHiredDateDuration.months()

        if(typeof formData.CoApplicant !== "undefined") {
            if(typeof formData.CoApplicant.EmploymentStartDate !== "undefined") {
                const hiredDateCA_m =  moment(formData.CoApplicant.EmploymentStartDate, 'YYYY-MM-DD');
                const diffHiredDateCADuration = moment.duration(moment().diff(hiredDateCA_m));
                formData.CoApplicant.HiredYears = diffHiredDateCADuration.years()
                formData.CoApplicant.HiredMonths = diffHiredDateCADuration.months()
            }
        }


        let URI = 'https://app-api.pinnaclefinance.com/loan-application';
        // console.log(JSON.stringify(formData))

        try {
            const rawResponse = await window.fetch(URI, {
                method: 'POST',
                headers: {
                    'Access-Control-Request-Headers': 'application/json',
                    'Content-Type': 'application/json',
                    'x-api-key': `${process.env.REACT_APP_API_KEY}`,
                },
                body: JSON.stringify(formData),

            });

            setIsLoading(false);
            const content = await rawResponse.json();

            // console.log(content);
         
            if (content.status_code === 200) {
                SetShowNotificationOk(true);
            }else if (content.status_code === 204) {
                setShowNotificationPinnacleParametersNotSeat(true);
            }else if (content.message === 'Endpoint request timed out') {
                SetShowNotificationErrorTimeout(true);
            }else if (content.status_code === 500) {
                SetShowNotificationError(true);
            }

        }
        catch (error) {
            console.log(error)
            setIsLoading(false);
            SetShowNotificationError(true);
        }

    };

    /*----------------------------------------- HANDLE INPUTS APPLICANT COAPPLICANT LOAN------------------------------------- */

    //HANDLE INPUTS
    //inputs applicant
    const handleInputApplicant = (event, inputName) => {
        
        if (inputName === "HomePhone" || inputName === "CellPhone" || inputName === "SocialSecurityNumber") {
            setApplicant({
                ...applicant,
                [inputName]: event.value,
            })
        } else {
            if (inputName === "HomeOwnership") {
                if(event.target.value === "P"){
                    setHasFreeAndClearHomeOwnership(true)
                }else{
                    setHasFreeAndClearHomeOwnership(false)
                }
            }

            setApplicant({
                ...applicant,
                [event.target.name]: event.target.value,
            })
        }

        
    }

    //inputs coapplicant
    const handleInputCoApplicant = (event, inputName) => {

        if (inputName === "HomePhone" || inputName === "CellPhone" || inputName === "SocialSecurityNumber") {
            setCoApplicant({
                ...coApplicant,
                [inputName]: event.value,
            })
        } else {
            setCoApplicant({
                ...coApplicant,
                [event.target.name]: event.target.value,
            })
        }
    }

    //inputs loan
    const handleInputLoan = event => {
        let value = event.target.value;

        if (event.target.name === "SalesTaxIncluded") {
            if (value === "true") {
                setIncludeTax(true);

                //setChangeStateIncludeTax(!changeStateIncludeTax);
                setChangeInputTax(!changeInputTax);
                // value = value
            } else if (value === "false") {

                setIncludeTax(false);
                //setChangeStateIncludeTax(!changeStateIncludeTax);
                setChangeInputTax(!changeInputTax)
                // value = value
            }
        } else if (event.target.name === "SellingPrice") {
            setChangeInputTax(!changeInputTax)
            // value = value
        } else if (event.target.name === "DownPayment") {
            setChangeInputTax(!changeInputTax)
            // value = value
        } else if (event.target.name === "SalesTax") {
            setChangeInputTax(!changeInputTax)
            // value = value
        }

        setLoan({
            ...loan,
            [event.target.name]: value,
        })
    }

    //input select coapplicant
    const handleInputHasCoApplicant = event => {

        setErrorsCoApplicant({});

        if (event.target.value === "true") {
            setHasCoapplicant(true)
        } else if (event.target.value === "false") {
            setHasCoapplicant(false)
        }

        setHasCoapplicantValue({
            ...hasCoApplicantValue,
            [event.target.name]: event.target.value
        })

    }

    const handleGoogleRecaptcha = e => {
        //e.preventDefault();
        window.grecaptcha.ready(function() {
          window.grecaptcha.execute('6Lewu3kdAAAAADkvu4_QzXKfCyE1k2UOVzbDwS73', {action: 'submit'}).then(function(token) {
              // Add your logic to submit to your backend server here.
              console.log('Validates Recaptcha...')
              if(token) setValidateRecaptcha(true);
          });
        });
    }

    useEffect(() => {
        handleGoogleRecaptcha();
    }, []);

    //hascoapplicant value
    useEffect(() => {
        if (hasCoApplicant === true) {
            setApplicant({
                ...applicant,
                HasCoApplicant: true,
            })

        } else if (hasCoApplicant === false) {
            setApplicant({
                ...applicant,
                HasCoApplicant: false,
            })
        }
    }, [hasCoApplicant]);

    //set CoApplicantType
    const setCoApplicantType = () => {
        setCoApplicant({
            ...coApplicant,
            CoApplicantType: coApplicant.ApplicantRelationship,
        })
    }

    useEffect(() => {
        if(isSubmitting){
            setErrors(validateForm(applicant, false));
        }
    }, [applicant]);

    useEffect(() => {
        if(isSubmittingCoApplicant){
            setErrorsCoApplicant(validateForm(coApplicant, true));
        }
    }, [coApplicant]);

    useEffect(() => {
        if(isSubmittingLoan){
            setErrorsLoan(validateFormLoan(loan));
        }
    }, [loan]);

    useEffect(() => {
        if(step===3){
            window.scrollTo(0,0)
        }
    }, [step]);

    /*----------------------------------------- FORM VALIDATIONS ------------------------------------- */

    //validate applicand and coapplicant
    const validateForm = (data, isCoApplicant) => {
        let errorList = {};

        //FirstName - text *
        if (!data.FirstName) {
            errorList.FirstName = "Please enter a name";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s]+$/.test(data.FirstName)) {
            errorList.FirstName = "The name can only contain letters";
        }

        //LastName - text *
        if (!data.LastName) {
            errorList.LastName = "Please enter a lastname";
        } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ'-\s\.]+$/.test(data.LastName)) {
            errorList.LastName = "The lastname can only contain letters";
        }

        if(isCoApplicant === false){
            //Address1 - text *
            if (!data.Address1) {
                errorList.Address1 = "Please enter a address";
            }

            //City - text *
            if (!data.City) {
                errorList.City = "Please enter a city";
            } else if (!/^[a-zA-ZáÁéÉíÍóÓúÚñÑüÜ\s\.]+$/.test(data.City)) {
                errorList.City = "The city can only contain letters";
            }

             //HomePhone *
            if (!data.HomePhone) {
                errorList.HomePhone = "Please enter a Home Phone";
            } else if (!/^[0-9]{10}$/.test(data.HomePhone)) {
                errorList.HomePhone = "The Business Phone must have 10 numbers";
            } else if (data.HomePhone === '0000000000') {
                errorList.HomePhone = "Please enter a valid phone number";
            }

        }

        //State - select *
        if (isCoApplicant === false) {
            if (!data.State) {
                errorList.State = "Please select an option";
            } else if (data.State === "State") {
                errorList.State = "Please select an option";
            }
        }

        //ZipCode - number *
        if (isCoApplicant === false) {
            if (!data.ZipCode) {
                errorList.ZipCode = "Please enter a Zip Code";
            } else if (!/^[0-9]{5}$/.test(data.ZipCode)) {
                errorList.ZipCode = "The zip code must have 5 numbers";
            }
        }

        //EmailAddress *
        if (isCoApplicant === false) {
            if (!data.EmailAddress) {
                errorList.EmailAddress = "Please enter a email";
            } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.EmailAddress)) {
                errorList.EmailAddress = "Please enter a valid email";
            }
        } else {
            if (!data.EmailAddress) {
                errorList.EmailAddress = "Please enter a email";
            } else if (!/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/.test(data.EmailAddress)) {
                errorList.EmailAddress = "Please enter a valid email";
            } else if (data.EmailAddress === applicant.EmailAddress) {
                errorList.EmailAddress = "The email must be different from the applicant's";
            }
        }

        //CellPhone - number *
        if (!data.CellPhone) {
            errorList.CellPhone = "Please enter a Cell Phone";
        } else if (!/^[0-9]{10}$/.test(data.CellPhone)) {
            errorList.CellPhone = "The Cell Phone must have 10 numbers";
        } else if (data.CellPhone === '0000000000') {
            errorList.CellPhone = "Please enter a valid phone number";
        }

        //SocialSecurityNumber - number *
        if (!data.SocialSecurityNumber) {
            errorList.SocialSecurityNumber = "Please enter a Social Security Number";
        } else if (!/^[0-9]{9}$/.test(data.SocialSecurityNumber)) {
            errorList.SocialSecurityNumber = "The social security number must have 9 numbers";
        } else if (data.SocialSecurityNumber === '000000000') {
            errorList.SocialSecurityNumber = "Please enter a valid Social Security Number";
        }

        //DateOfBirth *
        if (!data.DateOfBirth) {
            errorList.DateOfBirth = "Please enter a date";
        } else if (!data.DateOfBirth.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.DateOfBirth = "The format must be yyyy-mm-dd";
            } else {
                errorList.DateOfBirth = "The format must be dd/mm/yyyy";
            }
        } else if (data.DateOfBirth) {
            let dob = new Date(data.DateOfBirth);
            const nowDate = new Date();
            nowDate.setFullYear(nowDate.getFullYear() - 18);
            if (dob > nowDate) {
                errorList.DateOfBirth = "Must be over 18 years old";
            }
        }

        //PlaceOfEmployment
        if (!data.PlaceOfEmployment) {
            errorList.PlaceOfEmployment = "Please enter a Current Employer";
        } else if (data.PlaceOfEmployment.length < 3) {
            errorList.PlaceOfEmployment = "Employer name must be at least 3 characters long";
        }

        //Occupation * 
        if (!data.Occupation) {
            errorList.Occupation = "Please enter a Occupation";
        }

        //EmploymentStartDate
        if (!data.EmploymentStartDate) {
            errorList.EmploymentStartDate = "Please enter a date";
        } else if (!data.EmploymentStartDate.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
            if (isSafari) {
                errorList.EmploymentStartDate = "The format must be yyyy-mm-dd";
            } else {
                errorList.EmploymentStartDate = "The format must be dd/mm/yyyy";
            }
        } else if (data.EmploymentStartDate) {
            let dob2 = new Date(data.EmploymentStartDate);
            const nowDate = new Date();
            if (nowDate < dob2) {
                errorList.EmploymentStartDate = "The date must be in the past";
            }
        }

        //DateMovedIntoHome
        if (isCoApplicant === false) {
            if (!data.DateMovedIntoHome) {
                errorList.DateMovedIntoHome = "Please enter a date";
            } else if (!data.DateMovedIntoHome.match(/^\d\d\d\d[/-]\d\d[/-]\d\d$/)) {
                if (isSafari) {
                    errorList.DateMovedIntoHome = "The format must be yyyy-mm-dd";
                } else {
                    errorList.DateMovedIntoHome = "The format must be dd/mm/yyyy";
                }
            } else if (data.DateMovedIntoHome) {
                let dob2 = new Date(data.DateMovedIntoHome);
                const nowDate = new Date();
                if (nowDate < dob2) {
                    errorList.DateMovedIntoHome = "The date must be in the past";
                }
            }
        }

        //GrossMonthlyIncome *
        if (!data.GrossMonthlyIncome) {
            errorList.GrossMonthlyIncome = "Please enter a value. Enter 0 if no income.";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(data.GrossMonthlyIncome)) {
            errorList.GrossMonthlyIncome = "The value can only contain numbers";
        } else if (isCoApplicant && data.GrossMonthlyIncome == 0) {
            errorList.GrossMonthlyIncome = "The value must be greater than 0";
        }

        //HomeOwnership
        if (isCoApplicant === false) {
            if (!data.HomeOwnership) {
                errorList.HomeOwnership = "Please select a Home Ownership";
            }
        }

        //MortgagePayment
        if (isCoApplicant === false) {
            if(data.HomeOwnership !== "P"){
                if (!data.MortgagePayment) {
                    errorList.MortgagePayment = "Please enter a value";
                } else if (!/^[0-9]+\.?[0-9]*$/.test(data.MortgagePayment)) {
                    errorList.MortgagePayment = "The value can only contain numbers";
                }
            }
        }

        //MortgageBalance
        if (isCoApplicant === false) {
            if(data.HomeOwnership !== "P"){
                if (!data.MortgageBalance) {
                    errorList.MortgageBalance = "Please enter a value";
                } else if (!/^[0-9]+\.?[0-9]*$/.test(data.MortgageBalance)) {
                    errorList.MortgageBalance = "The value can only contain numbers";
                }
            }
        }
        // console.log(errorList)

        return errorList;
    };

    //validate loan
    const validateFormLoan = applicant => {
        let errorList = {};

        //DownPayment - number 
        if (!applicant.DownPayment && applicant.DownPayment !== 0) {
            errorList.DownPayment = "Please enter a value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.DownPayment)) {
            errorList.DownPayment = "The value can only contain numbers";
        } else if (parseInt(applicant.DownPayment) > parseInt(applicant.LoanAmount)) {
            errorList.DownPayment = "Should be less than Sale Amount";
        }

        //LoanAmount
        if (!applicant.LoanAmount) {
            errorList.LoanAmount = "Please enter a value";
        } else if (!/^[0-9]+\.?[0-9]*$/.test(applicant.LoanAmount)) {
            errorList.LoanAmount = "The value can only contain numbers";
        }

        //HomeImprovementProduct
        if (!applicant.HomeImprovementProduct) {
            errorList.HomeImprovementProduct = "Please enter a value";
        }

        return errorList;
    };

    /*----------------------------------------- FORMS SUBMIT ------------------------------------- */
    //submit applicant
    const handleSubmitApplicant = event => {
        event.preventDefault();
        //handle errors
        setErrors(validateForm(applicant, false));
        setIsSubmitting(isSubmitting+1);

    };

    //submit coapplicant
    const handleSubmitCoApplicant = (event, no_validate) => {
        event.preventDefault();
        
        //handle errors
        if(!no_validate) {
            setErrorsCoApplicant(validateForm(coApplicant, true));
        }else{
            // console.log(applicant.HasCoApplicant)
            if (applicant.HasCoApplicant === 'default'){
                let errorList = {};
                errorList.HasCoApplicant = "Please select an option";
                setErrorsCoApplicant(errorList);
                // console.log(errorList)
            }else {
                setErrorsCoApplicant({});
            }

        }
        setIsSubmittingCoApplicant(isSubmittingCoApplicant+1);

    };

    //submit loan
    const handleSubmitLoan = event => {
        event.preventDefault();

        setErrorsLoan(validateFormLoan(loan));
        setIsSubmittingLoan(isSubmittingLoan+1);
    };

    //submit terms
    const handleSubmitTerms = event => {
        event.preventDefault();

        if(termsCheck === false) {
            setTermsCheckBorderRed(true)
        }

        setIsSubmittingTerms(isSubmittingTerms+1);
    };

    /*----------------------------------------- USE EFFECTS POS FORMS ------------------------------------- */
    //post applicant
    useEffect(() => {
        if (Object.keys(errors).length === 0 && isSubmitting > 0) {
            nextStep();
        }
    }, [isSubmitting]);

    //post coapplicant
    useEffect(() => {
        if (Object.keys(errorsCoApplicant).length === 0 && isSubmittingCoApplicant > 0) {
            nextStep();
        } 
    }, [isSubmittingCoApplicant]);

    //post terms
    useEffect(() => {
        if (termsCheck && validateRecaptcha) {
            handleSubmitAllData();
        } 
    }, [isSubmittingTerms]);

    //post loan
    useEffect(() => {
        if (Object.keys(errorsLoan).length === 0 && isSubmittingLoan > 0) {
            nextStep();
        }
    }, [isSubmittingLoan]);

    /*----------------------------------------- STEPS Y TABS------------------------------------- */
    //set step

    const nextStep = () => {
        setStep(step + 1);
    };
    const prevStep = () => {
        setStep(step - 1);
    };
    const restar = () => {
        setStep(1);
    };

    //steps for create new application
    const stepsCounter = () => {
        const styletitlemobile1 = ["stepmobiletittle"];
        const styletitlemobile2 = ["stepmobiletittle"];
        const styletitlemobile3 = ["stepmobiletittle"];
        const styletitlemobile4 = ["stepmobiletittle"];

        const stylenumber1 = ["stepnumber"];
        const styletitle1 = ["steptittle"];
        const stylenumber2 = ["stepnumber"];
        const styletitle2 = ["steptittle"];
        const stylenumber3 = ["stepnumber"];
        const styletitle3 = ["steptittle"];
        const stylenumber4 = ["stepnumber"];
        const styletitle4 = ["steptittle"];

        if (step === 1) {
            stylenumber1.push("stepnumberactive");
            styletitle1.push("steptittleactive");
            styletitlemobile1.push("showstepmobiletittle");
        }
        if (step === 2) {
            stylenumber1.push("stepnumbercomplete");
            stylenumber2.push("stepnumberactive");
            styletitle2.push("steptittleactive");
            styletitlemobile2.push("showstepmobiletittle");
        }
        if (step === 3) {
            stylenumber1.push("stepnumbercomplete");
            stylenumber2.push("stepnumbercomplete");
            stylenumber3.push("stepnumberactive");
            styletitle3.push("steptittleactive");
            styletitlemobile3.push("showstepmobiletittle");
        }
        if (step === 4) {
            stylenumber1.push("stepnumbercomplete");
            stylenumber2.push("stepnumbercomplete");
            stylenumber3.push("stepnumbercomplete");
            stylenumber4.push("stepnumberactive");
            styletitle4.push("steptittleactive");
            styletitlemobile4.push("showstepmobiletittle");
        }


        return (
            <div className="stepscontainer">
                <div className="stepsmobiletittlecontainer">

                    <h3 className={styletitlemobile1.join(" ")}>Loan Parameters</h3>
                    <h3 className={styletitlemobile2.join(" ")}>Applicant Information</h3>
                    <h3 className={styletitlemobile3.join(" ")}>CoApplicant Information</h3>
                    <h3 className={styletitlemobile4.join(" ")}>Terms & Conditions</h3>

                </div>

                <div className="stepsdesktopcontainer">
                    {step === 1 ? null : <img src={iconArrowBack} onClick={prevStep} className="backButton" />}

                    <div className="step">
                        <span className={stylenumber1.join(" ")}>1</span>
                        <h3 className={styletitle1.join(" ")}>Loan Parameters</h3>
                    </div>
                    <div className="step">
                        <span className={stylenumber2.join(" ")}>2</span>
                        <h3 className={styletitle2.join(" ")}>Applicant Information</h3>
                    </div>
                    <div className="step">
                        <span className={stylenumber3.join(" ")}>3</span>
                        <h3 className={styletitle3.join(" ")}>CoApplicant Information</h3>
                    </div>
                    <div className="step">
                        <span className={stylenumber4.join(" ")}>4</span>
                        <h3 className={styletitle4.join(" ")}>Terms & Conditions</h3>
                    </div>

                </div>
            </div>
        );
    };

    /*------------------------------------------------------------------------------------------------- */

    switch (step) {
        default:
            return (
                <div>
                    <h1>breack</h1>
                    <button onClick={restar} value="Next">
                        Next
                    </button>
                </div>
            );
        case 1:
            return (
                <>
                    <div className="tabsMainContainer">

                        {stepsCounter()}

                        <div className="applicationFormContainer">
                            <h3 className="applicationFormContainer-tittle">Loan Parameters</h3>

                            {
                                FIApplicationId ?
                                    <h3 className="applicationFormContainer-tittle">Application Number: {FIApplicationId}</h3>
                                    : null
                            }

                            <form action="" id="form" className="form">

                                {/* <button onClick={ handleGoogleRecaptcha }>click me</button> */}

                                {/* HomeImprovementProduct */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Home Improvement Product</label>
                                    <select
                                        value={loan.HomeImprovementProduct}
                                        name="HomeImprovementProduct"
                                        onChange={handleInputLoan}
                                        className={`${errorsLoan.HomeImprovementProduct ? "inputErrorSelect" : "campoSelect"}`}
                                        placeholder="HomeImprovementProduct"
                                        required
                                    >
                                        <option value="1" disabled selected>
                                            Select from list...
                                        </option>
                                        <option value= "Awnings">Awnings</option>
                                        <option value= "Basement Finishing & Repair">Basement Finishing & Repair</option>
                                        <option value= "Bathroom Remodel">Bathroom Remodel</option>
                                        <option value= "Cabinet Refacing">Cabinet Refacing</option>
                                        <option value= "Decorative Concrete">Decorative Concrete</option>
                                        <option value= "Doors">Doors</option>
                                        <option value= "Electrical">Electrical</option>
                                        <option value= "Exterior Painting">Exterior Painting</option>
                                        <option value= "Energy Efficiency">Energy Efficiency</option>
                                        <option value= "Fencing">Fencing</option>
                                        <option value= "Flooring">Flooring</option>
                                        <option value= "Foundation Repair">Foundation Repair</option>
                                        <option value= "Garage Door">Garage Door</option>
                                        <option value= "General Home Improvement">General Home Improvement</option>
                                        <option value= "Gutters">Gutters</option>
                                        <option value= "Home Alarm Systems">Home Alarm Systems</option>
                                        <option value= "HVAC (Heating / Air-Conditioning)">HVAC (Heating / Air-Conditioning)</option>
                                        <option value= "Insulation">Insulation</option>
                                        <option value= "Kitchen Remodel">Kitchen Remodel</option>
                                        <option value= "Outdoor Deck">Outdoor Deck</option>
                                        <option value= "Patio Cover">Patio Cover</option>
                                        <option value= "Pool / Pool Deck">Pool / Pool Deck</option>
                                        <option value= "Plumbing">Plumbing</option>
                                        <option value= "Power Generator">Power Generator</option>
                                        <option value= "Roofing">Roofing</option>
                                        <option value= "Screen Enclosure">Screen Enclosure</option>
                                        <option value= "Stucco / Exterior Finish">Stucco / Exterior Finish</option>
                                        <option value= "Shutters">Shutters</option>
                                        <option value= "Siding">Siding</option>
                                        <option value= "Solar Equipment">Solar Equipment</option>
                                        <option value= "Spas">Spas</option>
                                        <option value= "Tub Liners">Tub Liners</option>
                                        <option value= "Windows">Windows</option>
                                        <option value= "Window Coverings">Window Coverings</option>
                                        <option value= "Walk-in Tub">Walk-in Tub</option>
                                        <option value= "Water Treatment">Water Treatment</option>

                                    </select>
                                    {errorsLoan.HomeImprovementProduct && <p className="errorMessage">{errorsLoan.HomeImprovementProduct}</p>}
                                </div>

                                {/* SaleAmount */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Sale Amount</label>
                                    <input
                                        type="number"
                                        value={loan.LoanAmount}
                                        name="LoanAmount"
                                        onChange={handleInputLoan}
                                        onBlur={() => { setLoan({ ...loan, 'LoanAmount': Math.round(parseFloat(loan.LoanAmount)) }) }}
                                        className={`${errorsLoan.LoanAmount ? "inputError" : "campo"}`}
                                        placeholder="Sale Amount"
                                        required
                                    />
                                    {errorsLoan.LoanAmount && <p className="errorMessage">{errorsLoan.LoanAmount}</p>}
                                </div>

                                {/* DownPayment */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Down Payment</label>
                                    <input
                                        type="number"
                                        value={loan.DownPayment}
                                        name="DownPayment"
                                        onChange={handleInputLoan}
                                        onBlur={() => { setLoan({ ...loan, 'DownPayment': Math.round(parseFloat(loan.DownPayment)) }) }}
                                        className={`${errorsLoan.DownPayment ? "inputError" : "campo"}`}
                                        placeholder="Down Payment"
                                        required
                                    />
                                    {errorsLoan.DownPayment && <p className="errorMessage">{errorsLoan.DownPayment}</p>}
                                </div>

                                {/* LoanAmount */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Loan Amount</label>
                                    <input
                                        type="number"
                                        value={loan.LoanAmount - (loan.DownPayment ? loan.DownPayment : 0)}
                                        name="LoanAmountFinal"
                                        className="campo"
                                        placeholder="Loan Amount"
                                    />
                                </div>
                            
                                <input
                                    onClick={handleSubmitLoan}
                                    type="submit"
                                    id="submit"
                                    className="fullwidth submit campo"
                                    placeholder="Continue"
                                    value="Continue"
                                    required
                                />

                            </form>

                        </div>
                    </div>
                </>
            );
        case 2:
            return (
                <>

                    <div className="tabsMainContainer">

                        {stepsCounter()}

                        <div className="applicationFormContainer">
                            <h3 className="applicationFormContainer-tittle">
                                Basic Information
                            </h3>
                            <form action="" id="form" className="form">

                                {/* <button onClick={()=>{nextStep()}}>Next</button> */}

                                {/* FirstName */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >First Name</label>
                                    <input
                                        type="text"
                                        name="FirstName"
                                        maxLength="20"
                                        onChange={handleInputApplicant}
                                        className={`${errors.FirstName ? "inputError" : "campo"}`}
                                        value={applicant.FirstName}
                                        placeholder="First name"
                                        required
                                    />
                                    {errors.FirstName && <p className="errorMessage">{errors.FirstName}</p>}
                                </div>

                                {/* LastName */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Last Name</label>
                                    <input
                                        type="text"
                                        name="LastName"
                                        maxLength="25"
                                        onChange={handleInputApplicant}
                                        className={`${errors.LastName ? "inputError" : "campo"}`}
                                        placeholder="Last Name"
                                        value={applicant.LastName}
                                        required
                                    />
                                    {errors.LastName && <p className="errorMessage">{errors.LastName}</p>}
                                </div>

                                {/* DateOfBirth */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Date of Birth</label>
                                    <input
                                        type="date"
                                        name="DateOfBirth"
                                        max="2050-01-01"
                                        onChange={handleInputApplicant}
                                        className={`${errors.DateOfBirth ? "inputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                        value={applicant.DateOfBirth}
                                    />
                                    {errors.DateOfBirth && <p className="errorMessage">{errors.DateOfBirth}</p>}
                                </div>

                                {/* SocialSecurityNumber */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Social Security Number</label>
                                    <NumberFormat
                                        value={applicant.SocialSecurityNumber}
                                        onValueChange={e => handleInputApplicant(e, 'SocialSecurityNumber')}
                                        displayType="input"
                                        type="text"
                                        name="SocialSecurityNumber"
                                        placeholder="Social Security Number"
                                        format="###-##-####"
                                        className={`${errors.SocialSecurityNumber ? "inputError" : "campo"}`}
                                    />
                                    {errors.SocialSecurityNumber && <p className="errorMessage">{errors.SocialSecurityNumber}</p>}
                                </div>

                                 {/* Address1 */}
                                 <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Street Address</label>
                                    <input
                                        type="text"
                                        value={applicant.Address1}
                                        name="Address1"
                                        maxLength="40"
                                        onChange={handleInputApplicant}
                                        className={`${errors.Address1 ? "inputError" : "campo"}`}
                                        placeholder="Street Address"
                                        required
                                    />
                                    {errors.Address1 && <p className="errorMessage">{errors.Address1}</p>}
                                </div>

                                {/* City */}
                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >City</label>
                                    <input
                                        type="text"
                                        value={applicant.City}
                                        name="City"
                                        maxLength="30"
                                        onChange={handleInputApplicant}
                                        className={`${errors.City ? "inputError" : "campo"}`}
                                        placeholder="City"
                                        required
                                    />
                                    {errors.City && <p className="errorMessage">{errors.City}</p>}
                                </div>

                                {/* State */}
                                <div className="mediowidth-right campoContainer">
                                    <label className="labelApplicationForm" >State</label>
                                    <select
                                        value={applicant.State}
                                        name="State"
                                        onChange={handleInputApplicant}
                                        className={`${errors.State ? "inputErrorSelect" : "campoSelect"}`}
                                        placeholder="State"
                                        required
                                    >
                                        <option value="1" disabled selected>
                                            State
                                        </option>
                                        <option value="AK">AK</option>
                                        <option value="AL">AL</option>
                                        <option value="AR">AR</option>
                                        <option value="AZ">AZ</option>
                                        <option value="CA">CA</option>
                                        <option value="CO">CO</option>
                                        <option value="CT">CT</option>
                                        <option value="DC">DC</option>
                                        <option value="DE">DE</option>
                                        <option value="FL">FL</option>
                                        <option value="GA">GA</option>
                                        <option value="GU">GU</option>
                                        <option value="HI">HI</option>
                                        <option value="IA">IA</option>
                                        <option value="ID">ID</option>
                                        <option value="IL">IL</option>
                                        <option value="IN">IN</option>
                                        <option value="KS">KS</option>
                                        <option value="KY">KY</option>
                                        <option value="LA">LA</option>
                                        <option value="MA">MA</option>
                                        <option value="MD">MD</option>
                                        <option value="ME">ME</option>
                                        <option value="MI">MI</option>
                                        <option value="MN">MN</option>
                                        <option value="MS">MS</option>
                                        <option value="MO">MO</option>
                                        <option value="MT">MT</option>
                                        <option value="NE">NE</option>
                                        <option value="NC">NC</option>
                                        <option value="ND">ND</option>
                                        <option value="NH">NH</option>
                                        <option value="NJ">NJ</option>
                                        <option value="NM">NM</option>
                                        <option value="NV">NV</option>
                                        <option value="NY">NY</option>
                                        <option value="OH">OH</option>
                                        <option value="OK">OK</option>
                                        <option value="OR">OR</option>
                                        <option value="PA">PA</option>
                                        <option value="PR">PR</option>
                                        <option value="RI">RI</option>
                                        <option value="SC">SC</option>
                                        <option value="SD">SD</option>
                                        <option value="TN">TN</option>
                                        <option value="TX">TX</option>
                                        <option value="UT">UT</option>
                                        <option value="VA">VA</option>
                                        <option value="VI">VI</option>
                                        <option value="VT">VT</option>
                                        <option value="WA">WA</option>
                                        <option value="WI">WI</option>
                                        <option value="WV">WV</option>
                                        <option value="WY">WY</option>
                                    </select>
                                    {errors.State && <p className="errorMessage">{errors.State}</p>}
                                </div>

                                {/* ZipCode */}
                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >Zip Code</label>
                                    <input
                                        type="text"
                                        value={applicant.ZipCode}
                                        name="ZipCode"
                                        onChange={handleInputApplicant}
                                        className={`${errors.ZipCode ? "inputError" : "campo"}`}
                                        placeholder="Zip Code"
                                        maxLength="5"
                                        required
                                    />
                                    {errors.ZipCode && <p className="errorMessage">{errors.ZipCode}</p>}
                                </div>

                                {/* HomePhone */}
                                <div className="mediowidth-right campoContainer">
                                    <label className="labelApplicationForm" >Home Phone</label>
                                    <NumberFormat
                                        value={applicant.HomePhone}
                                        onValueChange={e => handleInputApplicant(e, 'HomePhone')}
                                        displayType="input"
                                        type="text"
                                        name="HomePhone"
                                        placeholder="Home Phone"
                                        format="(###) - ### ####"
                                        className={`${errors.HomePhone ? "inputError" : "campo"}`}
                                    />
                                    {errors.HomePhone && <p className="errorMessage">{errors.HomePhone}</p>}
                                </div>

                                {/* CellPhone */}
                                <div className="mediowidth-left  campoContainer">
                                    <label className="labelApplicationForm" >Cell Phone</label>
                                    <NumberFormat
                                        value={applicant.CellPhone}
                                        onValueChange={e => handleInputApplicant(e, 'CellPhone')}
                                        displayType="input"
                                        type="text"
                                        name="CellPhone"
                                        placeholder="Cell Phone"
                                        format="(###) - ### ####"
                                        className={`${errors.CellPhone ? "inputError" : "campo"}`}
                                    />
                                    {errors.CellPhone && <p className="errorMessage">{errors.CellPhone}</p>}
                                </div>

                                 {/* EmailAddress */}
                                 <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Email</label>
                                    <input
                                        type="email"
                                        name="EmailAddress"
                                        onChange={handleInputApplicant}
                                        className={`${errors.EmailAddress ? "inputError" : "campo"}`}
                                        placeholder="Email"
                                        value={applicant.EmailAddress}
                                        required
                                    />
                                    {errors.EmailAddress && <p className="errorMessage">{errors.EmailAddress}</p>}
                                </div>

                            </form>

                            <h3 className="applicationFormContainer-tittle">
                                Employment
                            </h3>

                            <form action="" id="form" className="form">

                                {/* PlaceOfEmployment */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Place Of Employment</label>
                                    <input
                                        type="text"
                                        value={applicant.PlaceOfEmployment}
                                        name="PlaceOfEmployment"
                                        maxLength="40"
                                        onChange={handleInputApplicant}
                                        className={`${errors.PlaceOfEmployment ? "inputError" : "campo"}`}
                                        placeholder="Place Of Employment"
                                        required
                                    />
                                    {errors.PlaceOfEmployment && <p className="errorMessage">{errors.PlaceOfEmployment}</p>}
                                </div>

                                {/* Occupation */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Occupation</label>
                                    <input
                                        type="text"
                                        value={applicant.Occupation}
                                        name="Occupation"
                                        maxLength="20"
                                        onChange={handleInputApplicant}
                                        className={`${errors.Occupation ? "inputError" : "campo"}`}
                                        placeholder="Occupation"
                                        required
                                    />
                                    {errors.Occupation && <p className="errorMessage">{errors.Occupation}</p>}
                                </div>

                                {/* EmploymentStartDate */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Length of Employment - Hire Date</label>
                                    <input
                                        type="date"
                                        value={applicant.EmploymentStartDate}
                                        name="EmploymentStartDate"
                                        max="2050-01-01"
                                        onChange={handleInputApplicant}
                                        className={`${errors.EmploymentStartDate ? "inputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                    />
                                    {errors.EmploymentStartDate && <p className="errorMessage">{errors.EmploymentStartDate}</p>}
                                </div>

                                {/* GrossMonthlyIncome */}
                                <div className="fullwidth campoContainer">
                                    <label className="labelApplicationForm" >Gross Monthly Income</label>
                                    <input
                                        type="number"
                                        value={applicant.GrossMonthlyIncome}
                                        name="GrossMonthlyIncome"
                                        onChange={handleInputApplicant}
                                        onBlur={() => { setApplicant({ ...applicant, 'GrossMonthlyIncome': Math.round(parseFloat(applicant.GrossMonthlyIncome)) }) }}
                                        className={`${errors.GrossMonthlyIncome ? "inputError" : "campo"}`}
                                        placeholder="Gross Monthly Income"
                                        required
                                    />
                                    {errors.GrossMonthlyIncome && <p className="errorMessage">{errors.GrossMonthlyIncome}</p>}
                                </div>

                            </form>

                            <h3 className="applicationFormContainer-tittle">
                                Residence
                            </h3>

                            <form action="" id="form" className="form">

                                {/* HomeOwnership  */}
                                <div className="mediowidth-left campoContainer">
                                    <label className="labelApplicationForm" >Home Ownership</label>
                                    <select
                                        value={applicant.HomeOwnership}
                                        name="HomeOwnership"
                                        onChange={e => handleInputApplicant(e, 'HomeOwnership')}
                                        className={`${errors.HomeOwnership ? "inputErrorSelect" : "campoSelect"}`}
                                        placeholder="Home Ownership"
                                        required
                                    >
                                        <option value="disable" disabled selected>Residence Type</option>
                                        <option value="M">Own with Mortgage</option>
                                        <option value="P">Owned free and clear</option>
                                        <option value="R">Rent</option>

                                    </select>
                                    {errors.HomeOwnership && <p className="errorMessage">{errors.HomeOwnership}</p>}
                                    {applicant.HomeOwnership === "R" && <p className="errorMessage">Home owner must be included on the loan application</p>}
                                </div>

                                {/* DateMovedIntoHome */}
                                <div className="mediowidth-right campoContainer">
                                    <label className="labelApplicationForm" >Move in Date</label>
                                    <input
                                        type="date"
                                        value={applicant.DateMovedIntoHome}
                                        name="DateMovedIntoHome"
                                        max="2050-01-01"
                                        onChange={handleInputApplicant}
                                        className={`${errors.DateMovedIntoHome ? "inputError" : "campo"}`}
                                        placeholder="yyyy-mm-dd"
                                        required
                                    />
                                    {errors.DateMovedIntoHome && <p className="errorMessage">{errors.DateMovedIntoHome}</p>}
                                </div>

                                {!hasFreeAndClearHomeOwnership &&
                                    <div className="mediowidth-left campoContainer">
                                        <label className="labelApplicationForm" >Mortgage Payment</label>
                                        <input
                                            type="number"
                                            value={applicant.MortgagePayment}
                                            name="MortgagePayment"
                                            onChange={handleInputApplicant}
                                            onBlur={() => { setApplicant({ ...applicant, 'MortgagePayment': Math.round(parseFloat(applicant.MortgagePayment)) }) }}
                                            className={`${errors.MortgagePayment ? "inputError" : "campo"}`}
                                            placeholder="Mortgage Payment"
                                            required
                                        />
                                        {errors.MortgagePayment && <p className="errorMessage">{errors.MortgagePayment}</p>}
                                    </div>
                                }
                                {!hasFreeAndClearHomeOwnership &&
                                    <div className="mediowidth-right campoContainer">
                                        <label className="labelApplicationForm" >Mortgage Balance</label>
                                        <input
                                            type="number"
                                            value={applicant.MortgageBalance}
                                            name="MortgageBalance"
                                            onChange={handleInputApplicant}
                                            onBlur={() => { setApplicant({ ...applicant, 'MortgageBalance': Math.round(parseFloat(applicant.MortgageBalance)) }) }}
                                            className={`${errors.MortgageBalance ? "inputError" : "campo"}`}
                                            placeholder="Mortgage Balance"
                                            required
                                        />
                                        {errors.MortgageBalance && <p className="errorMessage">{errors.MortgageBalance}</p>}
                                    </div>
                                }

                            </form>

                            <form action="" id="form" className="form">
                                <button
                                    className="mediowidth-left campo back"
                                    onClick={prevStep}
                                >Back</button>
                            
                                <input
                                    onClick={handleSubmitApplicant}
                                    type="submit"
                                    id="submit"
                                    disabled = {applicant.HomeOwnership === "R" ? "true" : null}
                                    className={applicant.HomeOwnership === "R" ? "disable-button mediowidth-right submit campo" : "mediowidth-right submit campo"}
                                    placeholder="Save and continue"
                                    value="Continue"
                                    required
                                />
                            </form>
                        </div>
                    </div>
                </>
            );
        case 3:
            return (
                <>
                    <div className="tabsMainContainer">

                        {stepsCounter()}
                        
                            <div className="applicationFormContainer">

                                <h3 className="applicationFormContainer-tittle ">
                                Do you want to add a CoApplicant?
                                </h3>
                                
                                <div className="form">
                                    <div className="fullwidth campoContainer ">
                                        <label className="labelApplicationForm" >Has CoApplicant?</label>
                                        <select
                                            id=""
                                            name="HasCoApplicant"
                                            onChange={handleInputHasCoApplicant}
                                            className={`${errorsCoApplicant.HasCoApplicant ? "inputErrorSelect" : "campoSelect"}`}
                                            placeholder="Has CoApplicant"
                                            value={applicant.HasCoApplicant }
                                            required
                                        >
                                            <option value="default" disabled selected>
                                                Select
                                            </option>
                                            <option value="true">Yes</option>
                                            <option value="false">No</option>

                                        </select>
                                        {errorsCoApplicant.HasCoApplicant && <p className="errorMessage">{errorsCoApplicant.HasCoApplicant}</p>}
                                    </div>
                                </div>

                            </div>

                        {hasCoApplicant &&
                            <div className="applicationFormContainer">
                                <h3 className="applicationFormContainer-tittle">
                                    CoApplicant Basic Information
                                </h3>
                                <div className="form">

                                    {/* FirstName */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" > First Name</label>
                                        <input
                                            type="text"
                                            value={coApplicant.FirstName}
                                            name="FirstName"
                                            maxLength="20"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.FirstName ? "inputError" : "campo"}`}
                                            placeholder="First name"
                                            required
                                            autoFocus
                                        />
                                        {errorsCoApplicant.FirstName && <p className="errorMessage">{errorsCoApplicant.FirstName}</p>}
                                    </div>

                                    {/* LastName */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Last Name</label>
                                        <input
                                            type="text"
                                            value={coApplicant.LastName}
                                            name="LastName"
                                            maxLength="25"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.LastName ? "inputError" : "campo"}`}
                                            placeholder="Last Name"
                                            required
                                        />
                                        {errorsCoApplicant.LastName && <p className="errorMessage">{errorsCoApplicant.LastName}</p>}
                                    </div>

                                    {/* DateOfBirth */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Date of Birth</label>
                                        <input
                                            type="date"
                                            value={coApplicant.DateOfBirth}
                                            max="2050-01-01"
                                            name="DateOfBirth"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.DateOfBirth ? "inputError" : "campo"}`}
                                            required
                                        />
                                        {errorsCoApplicant.DateOfBirth && <p className="errorMessage">{errorsCoApplicant.DateOfBirth}</p>}
                                    </div>

                                    {/* SocialSecurityNumber */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Social Security Number</label>
                                        <NumberFormat
                                            value={coApplicant.SocialSecurityNumber}
                                            onValueChange={e => handleInputCoApplicant(e, 'SocialSecurityNumber')}
                                            displayType="input"
                                            type="text"
                                            name="SocialSecurityNumber"
                                            placeholder="Social Security Number"
                                            format="###-##-####"
                                            className={`${errorsCoApplicant.SocialSecurityNumber ? "inputError" : "campo"}`}
                                        />
                                        {errorsCoApplicant.SocialSecurityNumber && <p className="errorMessage">{errorsCoApplicant.SocialSecurityNumber}</p>}
                                    </div>

                                    {/* CellPhone */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Cell Phone</label>
                                        <NumberFormat
                                            value={coApplicant.CellPhone}
                                            onValueChange={e => handleInputCoApplicant(e, 'CellPhone')}
                                            displayType="input"
                                            type="text"
                                            name="CellPhone"
                                            placeholder="Cell Phone"
                                            format="(###) - ### ####"
                                            className={`${errorsCoApplicant.CellPhone ? "inputError" : "campo"}`}
                                        />
                                        {errorsCoApplicant.CellPhone && <p className="errorMessage">{errorsCoApplicant.CellPhone}</p>}
                                    </div>

                                    {/* EmailAddress */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Email (must be different than applicant email)</label>
                                        <input
                                            type="email"

                                            name="EmailAddress"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.EmailAddress ? "inputError" : "campo"}`}
                                            placeholder="Email"
                                            value={coApplicant.EmailAddress}
                                            required
                                        />
                                        {errorsCoApplicant.EmailAddress && <p className="errorMessage">{errorsCoApplicant.EmailAddress}</p>}
                                    </div>

                                </div>

                                <h3 className="applicationFormContainer-tittle">
                                    Employment
                                </h3>

                                <div className="form">

                                    {/* PlaceOfEmployment */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Place Of Employment</label>
                                        <input
                                            type="text"
                                            value={coApplicant.PlaceOfEmployment}
                                            name="PlaceOfEmployment"
                                            maxLength="40"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.PlaceOfEmployment ? "inputError" : "campo"}`}
                                            placeholder="Place Of Employment"
                                            required
                                        />
                                        {errorsCoApplicant.PlaceOfEmployment && <p className="errorMessage">{errorsCoApplicant.PlaceOfEmployment}</p>}
                                    </div>

                                    {/* Occupation */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Occupation</label>
                                        <input
                                            type="text"
                                            value={coApplicant.Occupation}
                                            name="Occupation"
                                            maxLength="20"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.Occupation ? "inputError" : "campo"}`}
                                            placeholder="Occupation"
                                            required
                                        />
                                        {errorsCoApplicant.Occupation && <p className="errorMessage">{errorsCoApplicant.Occupation}</p>}
                                    </div>

                                    {/* EmploymentStartDate */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Length of Employment - Hire Date</label>
                                        <input
                                            type="date"
                                            value={coApplicant.EmploymentStartDate}
                                            name="EmploymentStartDate"
                                            max="2050-01-01"
                                            onChange={handleInputCoApplicant}
                                            className={`${errorsCoApplicant.EmploymentStartDate ? "inputError" : "campo"}`}
                                            placeholder="yyyy-mm-dd"
                                            required
                                        />
                                        {errorsCoApplicant.EmploymentStartDate && <p className="errorMessage">{errorsCoApplicant.EmploymentStartDate}</p>}
                                    </div>

                                    {/* GrossMonthlyIncome */}
                                    <div className="fullwidth campoContainer">
                                        <label className="labelApplicationForm" >Gross Monthly Income</label>
                                        <input
                                            type="number"
                                            value={coApplicant.GrossMonthlyIncome}
                                            name="GrossMonthlyIncome"
                                            onChange={handleInputCoApplicant}
                                            onBlur={() => { setCoApplicant({ ...coApplicant, 'GrossMonthlyIncome': Math.round(parseFloat(coApplicant.GrossMonthlyIncome)) }) }}
                                            className={`${errorsCoApplicant.GrossMonthlyIncome ? "inputError" : "campo"}`}
                                            placeholder="Gross Monthly Income"
                                            required
                                        />
                                        {errorsCoApplicant.GrossMonthlyIncome && <p className="errorMessage">{errorsCoApplicant.GrossMonthlyIncome}</p>}
                                    </div>

                                </div>


                            </div>
                           
                        }

                        <div className="applicationFormContainer">

                            <div action="" id="form" className="form">

                                <button
                                    className="mediowidth-left campo back"
                                    onClick={prevStep}
                                >Back</button>

                                <input
                                    onClick={(event)=>handleSubmitCoApplicant(event, hasCoApplicantValue.HasCoApplicant === "true" ? false : true)}
                                    type="submit"
                                    id="submit"
                                    className="mediowidth-right submit campo"
                                    placeholder="Continue"
                                    value="Continue"
                                    required
                                />
                            </div>
                        </div>
                    </div>
                </>
            );
        case 4:
            return (
                <>
                    <div className="tabsMainContainer">

                        {stepsCounter()}

                        <div className="applicationFormContainer">

                            <div action="" id="form" className="form">

                                {/* Terms */}
                                <div className={termsCheckBorderRed ?  "fullwidth termsContainerError" : "fullwidth termsContainer" }>
                                    <p>By acknowledging below you certify that all information provided is true and complete. You authorize us to confirm the information in this application and to request and receive credit information about you in order to determine creditworthiness. You further authorize us to furnish information about your account to credit reporting agencies. Credit Reports: By submitting your application, you agree that we may obtain at any time your credit report for any legitimate purpose associated with your application, account or other requests, including but not limited to reviewing, modifying, renewing or collecting on your account. At your request you will be informed if such a report was obtained by us, and you will be given contact information for the consumer reporting agency that furnished the report. </p>
                                    <br/><h4>State Application Disclosures:</h4>
                                    <p><strong>California Residents: </strong>An applicant, if married, may apply for a separate account.</p> 
                                    <p><strong>New York Residents: </strong>A consumer report may be obtained in connection with your application. If credit is extended, the holder of the credit may obtain additional consumer reports in connection with any update, renewal or extension of the credit. If you ask, you will be told whether a consumer report was requested and the name and address of any consumer reporting agency that furnished the report.</p> 
                                    <p><strong>Ohio Residents: </strong>The Ohio laws against discrimination require that all creditors make credit equally available to all credit worthy customers, and that credit reporting agencies maintain separate credit histories on each individual upon request. The Ohio civil rights commission administers compliance with this law.</p>
                                    <p><strong>Rhode Island Residents: </strong>Consumer reports may be requested in connection with this application.</p>
                                    <p><strong>Vermont Residents: </strong>You authorize any potential creditor with which this credit application is shared to obtain and verify information about you (including consumer reports) as necessary or appropriate evaluate your application for credit. If credit is extended, you also authorize holder of the credit to obtain additional consumer reports in connection with the same transaction or extension of credit, for the purpose of reviewing the account, increasing the credit line on the account, for the purpose of taking collection action on the account, or for other legitimate purposes associated with the account.</p>
                                    <p><strong>Married Wisconsin Residents: </strong>No provision of a marital property agreement, unilateral statement under Section 766.59, Wisconsin Statutes, or a court decree under Section 766.70, Wisconsin Statutes, affecting marital property adversely affects the interest of a creditor, unless the creditor receives a copy of the agreement, statement or decree prior to granting the credit or unless the creditor has actual knowledge of the adverse provision when the obligation to the creditor is incurred.</p>
                                    <div className="checkCopyAddressInfo">
                                        <input type="checkbox" 
                                            name="isActive" 
                                            className="inputCopyAddressInfo" 
                                            {...termsCheck ? { checked: "checked" } : null}
                                            onClick={()=> setTermsCheck(!termsCheck)} /> 
                                        <label className="labelAuthorizationTerms" > Authorization</label>
                                    </div> 
                                    <p> I/we acknowledge that all the information I/we have provided is true and complete and that I/we have read the above disclosures.              </p>

                                </div>

                                <button
                                    className="mediowidth-left campo back"
                                    onClick={prevStep}
                                >Back</button>

                                <input
                                    onClick={(event)=>handleSubmitTerms(event)}
                                    type="submit"
                                    id="submit"
                                    className="mediowidth-right submit campo"
                                    placeholder="Submit"
                                    value="Submit"
                                    required
                                />
                            </div>
                        </div>

                        {showNotificationOk &&
                            <Notification {...isSafari && { flexRight: true }} type='popup' reload='true'  title='Loan Application created successfully. Thank you!' f={() => { window.location.reload();}}>
                                <img src={iconCheckBlue} alt="check" style={{height: '70px'}}/>
                            </Notification>}

                        {showNotificationError &&
                        <Notification {...isSafari && { flexRight: true }} errorResponse={errorResponse} error={true} type='popup' title='We had a problem.  Please try again later.' f={() => { SetShowNotificationError(false) }}>
                            <img src={iconSadFace} alt="check" style={{height: '70px'}}/>
                        </Notification>}

                        {showNotificationErrorTimeout &&
                        <Notification {...isSafari && { flexRight: true }} errorResponse={errorResponse} error={true} type='popup' title='Thank you for submitting your application. We will be in touch with you soon!' f={() => { window.location.reload(); }}>
                            <img src={iconAdmiration} alt="check" style={{height: '70px'}}/>
                        </Notification>}

                        {showNotificationPinnacleParametersNotSeat &&
                        <Notification {...isSafari && { flexRight: true }} type='popup' title='Dealer not found.' f={() => { setShowNotificationPinnacleParametersNotSeat(false) }}>
                            <img src={iconAdmiration} alt="check" style={{height: '70px'}}/>
                        </Notification>}

                        {isLoading && <SpinnerPopUp />}
                    </div>
                </>
            );
        
    }

}

export default PinnacleForm;
