import React from 'react'
import s from '../assets/styles/NotificationPinnacleOff.module.css'

const NotificationPinnacleOff = () => {

    return (
        <div className={s.notificationPinOff_mainContainer}>
            <p>Pinnacle system hours are from 6:00 AM to midnight (CT).</p> 
        </div>
    )
    
}

export default NotificationPinnacleOff;