import React, {useState, useEffect} from 'react'

const useIsSafari = () => {

    const [isSafari, setIsSafari] = useState(false)

    useEffect(()=>{
        const isSafariR = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof window.safari !== 'undefined' && window.safari.pushNotification));
        //console.log(isSafariR)

        if(isSafariR){
            setIsSafari(true) 
        }

    }, [])

   

    return isSafari;
}

export default useIsSafari;