import React from 'react';
import logo from '../assets/images/pinnacleLogo.png'
import s from '../assets/styles/Header.module.css'


const Header= (props)=>{
    
    return(

        <div className={s.header}>
            <img src={logo} alt=""/> 
        </div>
    
)};

export default Header;