import React from 'react';
import { useParams } from 'react-router-dom';
import PinnacleForm from '../components/PinnacleForm';
import s from '../assets/styles/Home.module.css'
import NoIdWarningMessage from '../components/NoIdWarningMessage';
import useIspPinnacleApiAvailable from '../hooks/useIsPinnacleApiAvailable'
import NotificationPinnacleOff from '../components/NotificationPinnacleOff';


const Home = ()=>{

    const id = useParams().id
    const isPinnacleApiAvailable = useIspPinnacleApiAvailable()

    // console.log(isPinnacleApiAvailable)
    
    return(

        <>
            <div className={s.home}>
                {
                    (id && isPinnacleApiAvailable) ?
                    <PinnacleForm dealerUUID={id}/>
                    :
                    !isPinnacleApiAvailable ?
                    <NotificationPinnacleOff />
                    :
                    <NoIdWarningMessage />
                }        
            </div>
           
        </>
    
)};

export default Home;