import moment from 'moment-timezone';

const useIsPinnacleApiAvailable = () => {

    const localTime = new Date().getTime()
    const today = moment(localTime).tz('America/Chicago').format('YYYY-MM-DD')   
    const ct = moment(localTime).tz('America/Chicago');
    const time6AM = moment.tz(today + " 06:00", "America/Chicago");
    const timeMidNight = moment.tz(today + " 00:00", "America/Chicago");

    // return true;
    if( ct > timeMidNight && ct < time6AM ){
        return false
    }else{
        return true
    }
}

export default useIsPinnacleApiAvailable