import React from 'react';
import Header from './Header';
import Footer from './Footer';


const Layout = (props)=>{
    
    return(

        <>
            <Header>Header</Header>
            {props.children}
            <Footer>Footer</Footer>

        </>
    
)};

export default Layout;