import React from 'react';
import Spinner from 'react-spinner-material';
import '../assets/styles/SpinnerPopUp.css'

const SpinnerPopUp = () =>{

    return(
        <div className="popUpContainer2">
          
            <Spinner
                size={70}
                color={"white"}
                stroke={4}
                visible={true}
              />
          
        </div>
    )
}

export default SpinnerPopUp;