import React from 'react'
import s from '../assets/styles/NoIdWarningMessage.module.css'

const NoIdWarningMessage = () => {
    return (
        <div className={s.NoIdWarningMessage}>
            <p>Invalid URL, please provide a Dealer ID</p>
        </div>
    )
}

export default NoIdWarningMessage
