import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from '../pages/Home';
import Layout from '../components/Layout'


function App() {
  return (
    <>

      <div>
        <BrowserRouter>
        
          <Layout>
            <Routes>
              
              <Route path="/:id" element={<Home />} />
              <Route path="/" element={<Home />} />  
              <Route element={<Home />} />  
              
            </Routes>
          </Layout> 
          
            
        </BrowserRouter>
      </div>
    
    </>
  );
}

export default App;
