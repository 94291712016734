import React, { useState } from "react";
import '../assets/styles/Notification.css'
import iconX from "../assets/images/iconX.png";
import {Link} from 'react-router-dom';

const Notification = props => {
  const [showPop, setShowPop] = useState(true);

  const closePopUp = () => {
    setShowPop(false);
  };

  const notificationPopUp = () => {
    if (showPop) {
      return (
        <div className={` ${props.flexRight ? "justifyContentRight" : "popUpContainer" }`}>
          <div className="popUp">
            <i className="fas fa-times" />
            {
              props.link ? null
            :
              <img
              src={iconX}
              className="closePopUpIcon"
              onClick={props.f}
              alt="close"
              />
            }
           
            {props.children}

            <h2 className="popUpTittle">{props.title}</h2>

            <p className="popUpMessage">{props.text}</p>

            
            

            {/* {props.reload &&
                  <button onClick={()=>{window.location.reload()}} className="popUpButtom"><span className="popUpButtomText">Done</span></button>       
            } */}
            
          </div>
        </div>
      );
    }
  };

  const notificationCard = () => {
    if (showPop) {
      return (
        <div
          className={
            props.color === "red"
              ? "notificationCardRed"
              : "notificationCardGreen"
          }
        >
          <div className="notificationCardText">
            <h3 className="notificationCardTittle">{props.title}</h3>
            <p className="notificationCardMessage">{props.text}</p>
          </div>
          <img
            src={iconX}
            className="notificationCardClose"
            onClick={props.f}
            alt="close"
          />
        </div>
      );
    }
  };



  return (
    <>{props.type === "popup" ? notificationPopUp() : notificationCard()}</>
  );
};

export default Notification;
